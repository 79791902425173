import * as THREE from 'three'
// 控制器
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
// fontLoader
import { FontLoader } from 'three/addons/loaders/FontLoader.js';
// textgeometry
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
// gui
import { GUI } from 'dat.gui';
// stats
import Stats from 'three/addons/libs/stats.module.js';
// const stats = new Stats();
// document.body.appendChild(stats.domElement);
const gui = new GUI()

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
window.addEventListener('resize', () => {
    // updata sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    // 更新相机
    camera.aspect = sizes.width / sizes.height // update camera aspect
    camera.updateProjectionMatrix() // Update Matrix
    // Update size
    renderer.setSize(sizes.width, sizes.height)
    // 这里一般用于窗口在多个屏幕上流转时能有一个比较好的显示效果
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color('#444')
/* 
texture
*/
const textLoader = new THREE.TextureLoader()
const matcapTexture = textLoader.load('/texture/matcaps/4.png')
// const matcapTexture = textLoader.load('https://makio135.com/matcaps/64/3E95CC_65D9F1_A2E2F6_679BD4-64px.png')

/* 
    fontLoader
*/
const fontLoader = new FontLoader()
fontLoader.load(
    '/fonts/helvetiker_regular.typeface.json',
    (font) => {
        const textGeometry = new TextGeometry(
            `-shylie-`,
            {
                font,
                size: 2,
                depth: .05,
                curveSegments: 12, //曲线上点的数量。默认值为12，也就是面数
                bevelEnabled: true, // 是否开启斜角，默认为false。
                bevelThickness: 0.03, //文本上斜角的深度，默认值为20。
                bevelSize: 0.02, //.斜角与原始文本轮廓之间的延伸距离。默认值为8。
                bevelOffset: 0,
                bevelSegments: 5
            }
        )

        /*
         使用盒包围使得文本在场景中心 
         两种方式：一种是利用 BufferGeometry 类上的center方式，
         第二种是自行通过cpmputedboundingBox来对模型 translate 进行偏移
         */
        textGeometry.center()
        // textGeometry.computeBoundingBox() // 要获取到模型边界，必须要先进行显式计算才能访问到 boundingbox
        // textGeometry.translate(
        //     // 这里需要减去上面设置的斜角长度，这样会得到一个比较准确的值，y轴会跟字体的高度相关
        //     -(textGeometry.boundingBox.max.x - 0.02) * 0.5,
        //     -(textGeometry.boundingBox.max.y - 0.02) * 0.5,
        //     -(textGeometry.boundingBox.max.z - 0.03) * 0.5
        // )
        const textmaterial = new THREE.MeshMatcapMaterial({ matcap: matcapTexture }) // 材质
        // textmaterial.map = texture // 贴图
        // textmaterial.wireframe = true
        const textMesh = new THREE.Mesh(textGeometry, textmaterial) // 网格模型
        scene.add(textMesh)
        // gui.add(textMesh.rotation, 'x').min(0).max(Math.PI * 2).step(.1)

        // add random tour * count
        /* 
            这里使用 InstanceMesh来渲染大量的相同元素，性能优化
        */
        const count = 500
        const tourGeometry = new THREE.TorusGeometry(0.1, 0.06, 16, 16)
        const tourMesh = new THREE.InstancedMesh(tourGeometry, textmaterial, count)

        const matrix = new THREE.Matrix4();
        const randomizeMatrix = function () {

            const position = new THREE.Vector3();
            const quaternion = new THREE.Quaternion(); //四元数 ，用于实现 rotation
            const scale = new THREE.Vector3();

            return function (matrix) {

                position.x = (Math.random() - .5) * 20;
                position.y = (Math.random() - .5) * 20;
                position.z = (Math.random() - .5) * 20;
                quaternion.random();

                scale.x = scale.y = scale.z = Math.random() * 1;
                /* 
                .compose ( position : Vector3, quaternion : Quaternion, scale : Vector3 ) : this
                设置将该对象位置 position，四元数quaternion 和 缩放scale 组合变换的矩阵。
                */
                matrix.compose(position, quaternion, scale);
            };

        }();
        for (let i = 0; i < count; i++) {
            randomizeMatrix(matrix);
            /* 
            .setMatrixAt ( index : Integer, matrix : Matrix4 ) : undefined
index: 实例的索引。值必须在 [0, count] 区间。 */
            tourMesh.setMatrixAt(i, matrix);

        }
        scene.add(tourMesh)

        // for (let i = 0; i < 10000; i++) {
        //     const tourMesh = new THREE.Mesh(tourGeometry, tourMaterial)
        //     tourMesh.position.x = (Math.random() - .5) * 5
        //     tourMesh.position.y = (Math.random() - .5) * 5
        //     tourMesh.position.z = (Math.random() - .5) * 5

        //     tourMesh.rotation.x = Math.random() * Math.PI
        //     tourMesh.rotation.y = Math.random() * Math.PI

        //     const scale = Math.random()
        //     tourMesh.scale.set(scale, scale, scale)

        //     scene.add(tourMesh)
        // }
    },
    () => { console.log('proserce') },
    (err) => { console.log('err', err) },
)

// Canvas
const canvas = document.querySelector('canvas.webgl')
window.addEventListener('resize', () => {
    // updata sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight
    // 更新相机
    camera.aspect = sizes.width / sizes.height // update camera aspect
    camera.updateProjectionMatrix() // Update Matrix
    // Update size
    renderer.setSize(sizes.width, sizes.height)
    // 这里一般用于窗口在多个屏幕上流转时能有一个比较好的显示效果
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})


const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.outputColorSpace = THREE.LinearSRGBColorSpace
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2)) // 设置像素比

// const geometry = new THREE.BoxGeometry(1, 1, 1)
// const material = new THREE.MeshBasicMaterial()
// material.color.set('green')
// material.transparent = true
// material.opacity = .5
// // material.wireframe = true
// const mesh = new THREE.Mesh(
//     geometry,
//     material
// )

// scene.add(mesh)

const camera = new THREE.PerspectiveCamera(75,
    sizes.width / sizes.height, .1,
    100)

/**
 * 控制器,可以使用 target 来控制控制器的焦点
 * 
 */
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true // 开启阻尼

camera.position.z = 10
camera.lookAt(new THREE.Vector3(0, 0, 0))

scene.add(camera) // 虽然好像加不加无所谓，但还是最好加上


const axesHelper = new THREE.AxesHelper(2)
// scene.add(axesHelper)
renderer.render(scene, camera)
// tick
const clock = new THREE.Clock()
const tick = () => {
    const elapsedTime = clock.getElapsedTime()


    controls.update()
    // stats.update();
    renderer.render(scene, camera)
    window.requestAnimationFrame(tick)
}
tick()